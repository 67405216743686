import React, { createContext, useContext } from 'react';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const config = {
        //apiUrl: 'https://oaa.missionscheduler.com/api/'
        apiUrl: 'https://oaa.missionscheduler.com/api/'
    };

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);
